import React, { useEffect, useState, useRef } from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { myScanService } from '../services/scan.service';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';
import Logo from "../assets/logoByAds.png";

import '../App.css';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    backgroundColor: 'black',
    height: '100vh',
  },
  videoPlayer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    '@media (orientation: landscape)': {
      objectFit: 'cover',
    },
    objectFit: 'contain',
    background: "transparent url('../assets/logoArtDesignStory') no-repeat 0 0",
    '-webkit-background-size': 'cover',
    '-moz-background-size': 'cover',
    '-o-background-size': 'cover',
    backgroundSize: 'cover',
  }
}));

const Scan = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [token, setToken] = useState(null);
  const [cover, setCover] = useState(false);
  const myPlayerRef = useRef();

  const disableBodyScroll = () => {
    const body = document.querySelector('body');
    let scrollPosition = 0;

    // enable() {
    scrollPosition = window.pageYOffset;
    body.style.overflow = 'hidden';
    body.style.position = 'fixed';
    body.style.top = `-${scrollPosition}px`;
    body.style.width = '100%';
    // },
    //   disable() {
    //     $body.style.removeProperty('overflow');
    //     $body.style.removeProperty('position');
    //     $body.style.removeProperty('top');
    //     $body.style.removeProperty('width');
    //     window.scrollTo(0, scrollPosition);
    // };
  }

  useEffect(() => {
    initialize();
  }, [props])

  useEffect(() => {
    disableBodyScroll();
    const search = new URLSearchParams(document.location.search);
    setToken({
      target: search.get('target'),
    })
    initialize();
  }, [])

  const initialize = async () => {
    if (!("mediaDevices" in navigator))
      navigator.mediaDevices = {};

    if (!"getUserMedia" in navigator.mediaDevices) {
      navigator.mediaDevices.getUserMedia = (constraints) => {
        const getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
        if (!getUserMedia) {
          setErrorMessage('unknown')
          return Promise.reject(new Error("GetUserMedia Not Implemented"));
        }

        return new Promise((resolve, reject) => {
          getUserMedia.call(navigator, constraints, resolve, reject);
        })
      }
    }

    const videoInputs = await getListOfVideoInputs();

    if (videoInputs.length) {
      const newConstraints = {
        video: {
          width: { idea: 4096 },
          height: { ideal: 2160 },
        }
      };
      if (isMobile) {
        newConstraints.video.facingMode = { exact: "environment" };
      }
      navigator.mediaDevices.getUserMedia(newConstraints)
        .then(stream => {
          myPlayerRef.current.srcObject = stream;
          if (isMobile) {
            setTimeout(() => setCover(true), 700);
          }
          setErrorMessage('none');
        })
        .catch(error => {
          setErrorMessage('errorStream')
        })
    } else {
      setErrorMessage('noCamera');
    }
  }

  const getListOfVideoInputs = async () => {
    const enumerateDevices = await navigator.mediaDevices.enumerateDevices();
    return enumerateDevices.filter(device => device.kind === "videoinput");
  }

  const resizeImage = (str) => {
    return new Promise(resolve => {
      const img = new Image();
      img.src = str;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;
        if (width > height) {
          const ratio = img.width / img.height;
          if (height > 640) {
            width = 640;
            height = 640 / ratio;
          }
        } else {
          const ratio = img.height / img.width;
          if (width > 640) {
            width = 640 / ratio;
            height = 640;
          }
        }
        console.log(img.width + ' => ' + width, img.height + ' => ' + height);
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL());
      }
    })
  }

  const timeoutFetching = () => {
    setTimeout(() => setFetching(false), 500);
  }

  const handleCapture = () => {
    setFetching(true);
    const canvas = document.createElement("canvas");
    canvas.width = myPlayerRef.current.videoWidth;
    canvas.height = myPlayerRef.current.videoHeight;
    const context = canvas.getContext("2d");
    context.drawImage(myPlayerRef.current, 0, 0, canvas.width, canvas.height);
    // myPlayerRef.current.srcObject.getVideoTracks().forEach(track => {
    //   track.stop();
    // });
    const result = resizeImage(canvas.toDataURL()).then(finalImage => {
      // finalImage is the final image to transfert
      myScanService.sendImage(token.target, finalImage, timeoutFetching)
        .catch(err => {
          setFetching(false);
          enqueueSnackbar(t('toasts.failedMatch'), { variant: 'error' });
        })
    })
  }

  const handleRedirect = () => {
    history.back();
  }

  const ErrorComponent = () => {
    const errorDetails = errorMessage === "noCamera" ? t('errors.noCamera') : errorMessage === "errorStream" ? t('errors.stream') : t('errors.unknown');
    return (
      <div className='error-component'>
        <div className="error-container">
          <span className="error-title">{t('errors.errorTitle')}</span>
          <span className="error-details">{errorDetails}</span>
          <span className="error-details-message">{t('errors.tryAgain')}</span>
        </div>
      </div>
    )
  }

  if (errorMessage && errorMessage !== 'none') {
    return <ErrorComponent />
  } else {
    return (
      <div className={classes.rootContainer}>
        <div
          className="redirect-button"
          onClick={handleRedirect}
        >
          <span
            className="material-symbols-outlined"
          >
            arrow_circle_left
          </span>
        </div>
        <video
          poster={Logo}
          className={classes.videoPlayer}
          playsInline
          ref={(playerRef) => {
            myPlayerRef.current = playerRef;
          }}
          autoPlay
          style={{
            objectFit: !isMobile ? 'contain' : isMobile && !cover ? 'contain' : 'cover',
          }}
        />
        {fetching ?
          <div className="fetching-spinner">
            <CircularProgress size={75} />
          </div>
          :
          <button
            className="capture-button"
            onClick={handleCapture}
          >
            Scan
          </button>
        }

      </div>
    )
  }
}

export default Scan;
