import {createTheme, responsiveFontSizes} from "@material-ui/core/styles";
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
// default breakpoints
// value         |0px     600px    960px    1280px   1920px
// key           |xs      sm       md       lg       xl
// screen width  |--------|--------|--------|--------|-------->
// range         |   xs   |   sm   |   md   |   lg   |   xl
//

const breakpoints = createBreakpoints({})
// / outputs {xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920}

let theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#D3AF5F',
    },
  },
  typography: {
    fontFamily: [
      'Lato',
      'sans-serif',
    ].join(','),
    h4: {
      fontSize: '14px',
      color: '#fff',
      [breakpoints.between('xs', 'sm')]: {
        fontSize: '12px',
      },
      [breakpoints.up('sm')]: {
        fontSize: '14px',
      },
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: '1rem',
        '&$disabled': {
          color: 'rgba(211,175,95,0.3)',
          backgroundColor: 'rgba(211,175,95,0.3)',
        },
      },
      containedPrimary: {
        backgroundColor: 'rgb(211,175,95)',
        color: 'rgb(48,48,48)',
      },
      contained: {
        '&:disabled': {
          color: 'rgba(48,48,48,0.5)',
          backgroundColor: 'rgba(211,175,95,0.3)',
        },
      },
    },
    MuiInputBase: {
      input: {
        '&:-internal-autofill-selected': {
          '-webkit-box-shadow': '0 0 0 30px #424242 inset',
          '-webkit-text-fill-color': 'white !important',
        },
        '&:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 30px #424242 inset',
          '-webkit-text-fill-color': 'white !important',
          caretColor: 'white',
        },
        '&:-webkit-autofill::first-line': {
          fontSize: '16px',
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        '&:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 30px #424242 inset',
        },
        color: 'white',
      },
      notchedOutline: {
        borderColor: 'rgba(211,175,95,0.3)',
      },
    },
    MuiTypography: {
      root: {
        color: 'rgba(255, 255, 255, 0.7)',
      },
    },
    MuiLink: {
      root: {
        '&:hover': {
          color: '#D3AF5F',
        }
      }
    },
    MuiBadge: {
      colorSecondary: '#D3AF5F'
    },
    MuiSpeedDialAction: {
      staticTooltipLabel: {
        color: '#D3AF5F',
        fontWeight: 800,
      }
    },
  },
});

theme = responsiveFontSizes(theme);
export {theme};
