import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { history } from '../helpers/history';
import Scan from '../views/Scan';

function AppRoutes() {
  return (
    <Router history={history}>
      <div className='App'>
        <Switch>
          <Route path="*" component={Scan} />
        </Switch>
      </div>
    </Router>
  )
}

export { AppRoutes };