export const myScanService = {
  sendImage,
}

function sendImage(targetId, finalImage, fetching) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json;charset=UTF-8'},
    body: JSON.stringify({
      image: finalImage
    })
  };

  return fetch(`${process.env.REACT_APP_API_URL}/targets/${targetId}/scan`, requestOptions)
  .then(async (res) => {
    if (res.status < 300) {
        window.location.href = await res.text();
        fetching();
        return Promise.resolve();
    } else {
      fetching();
      const error = (res && res.message) || res.statusText;
      return Promise.reject(error);
    }
  })
}