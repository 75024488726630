import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { AppRoutes } from './routes/AppRoutes';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';

import './i18n/i18n';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {ThemeProvider as MuiThemeProvider} from "@material-ui/styles";
import {theme} from "./helpers/theme";

const notistackRef = React.createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
}

const useStyles = makeStyles((theme) => ({
  error: {
    backgroundColor: '#484848 !important',
    color: '#f55c5c !important',
  },
  success: {
    backgroundColor: '#484848 !important',
    color: '#CEB673 !important',
  },
  info: {
    backgroundColor: '#484848 !important',
    color: '#a5893e !important',
  },
  warning: {
    backgroundColor: '#484848 !important',
    color: '#f5925c !important',
  },
}));

function ZeApp() {
  const classes = useStyles();
  return (
    <React.StrictMode>
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          ref={notistackRef}
          action={(key) => (
            <IconButton onClick={onClickDismiss(key)}>
              <CloseIcon />
            </IconButton>
          )}
          classes={{
            variantError: classes.error,
            variantSuccess: classes.success,
            variantInfo: classes.info,
            variantWarning: classes.warning,
          }}
        >
          <AppRoutes />
        </SnackbarProvider>
      </MuiThemeProvider>
    </React.StrictMode>
  )
}

ReactDOM.render(
  <ZeApp/>
  , document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
